<template>
	<div class="order_wrap">
		<v-container>
			<div class="message_box mt-3">
				<span class="txt_body1"
					>세이프키 발급이 불가능한 고객입니다.
					<br />
					주민등록번호를 이용해 발급되므로 고객님이 직접 발급받을 수 있도록
					안내바랍니다.</span
				>
			</div>
		</v-container>
		<div class="bottom_box px-6">
			<v-btn color="primary" class="col_1_small" @click="goMain()">
				확인
			</v-btn>
		</div>
	</div>
</template>

<script>
export default {
	props: {},
	mounted() {},
	data() {
		return {}
	},
	methods: {
		goMain() {
			this.$router.push({ name: 'order-verify' })
		}
	}
}
</script>

<style scoped lang="scss"></style>
